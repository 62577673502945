.css-8nrica-MuiInputBase-root-MuiOutlinedInput-root {
    width: 100% !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box unset;
    display: -webkit-inline-flex unset;
    display: -ms-inline-flexbox unset;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    display: grid!important;
    place-items: center!important;
    width: 100%!important;
    height: auto!important;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem!important;
    font-weight: 400!important;
    line-height: 1.4!important;
    color: #495057!important;
    background-color: #ffffff!important;
    -webkit-background-clip: padding-box!important;
    background-clip: padding-box!important;
    border: 0.0625rem solid #d2d6da;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    -ms-appearance: none!important;
    appearance: none!important;
    border-radius: 0.5rem;
    -webkit-transition: box-shadow 150ms ease,border-color 150ms ease,padding 150ms ease!important;
    transition: box-shadow 150ms ease,border-color 150ms ease,padding 150ms ease!important;
    position: relative;
    border-radius: 4px;
    display: -webkit-box unset!important;
    display: -webkit-flex unset !important;
    display: -ms-flexbox unset !important;
    display: flex unset !important;
    padding: 0.5rem 1.75rem 0.5rem 0.75rem!important;
    border: 0.0625rem solid #d2d6da;
    border-radius: 0.5rem!important;
}